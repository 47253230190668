<template>
  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  components: {}
})
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  min-height: 100vh;
  height: 100%;
}
</style>
